export enum THEMES {
    UE = 'UE',
    SR = 'SR',
    EXT02 = 'EXT02',
    SOFTRIG = 'SOFTRIG',
    SOFTRIG_TRAINING = 'SOFTRIG_TRAINING',
    AZETS = 'AZETS',
    EIKA = 'EIKA',
    SB_SOR = 'SB_SOR',
}

export interface ThemeData {
    theme: THEMES;
    appName: string;
    appProvider: string;
    translationOverrides: any;
    saveButtonClass?: string;
    dashboardConfigs: any;
    tableColumnOverrides: any;
    featureBlacklists: any;
    sanityImageBaseUrl?: string;

    supportArticleUrls: {
        invoicing?: string;
        taxMandatoryInfo: string;
        subsequentVatInvoicing?: string;
        retrospectiveVatRefundsInfo?: string;
    };

    init: {
        illustration: string;
        background: string;
        login_background: string;
        login_background_height: string;
        signup_background_height: string;
    };

    widgets: {
        empty_state_illustration: string;

        primary: string;
        bad: string;

        bar_foreground: string;

        pie_colors: string[];

        operating_profits?: {
            income: string;
            cost: string;
            result: string;
        };

        unpaidWidgetColors: {
            chartColor: string;
            backgroundColor: string;
            textColor: string;
            borderColor: string;
        }[];
        project_result_colors: string[];
    };

    icons: { [name: string]: string };
}
